import React from "react";
import { H2, P2 } from "app/components/Typography";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import env from "helpers/env";
import { AppStoreBadge, GooglePlayBadge } from "app/components/Icon";
import styles from "./styles.module.scss";

interface Props {
  title?: string;
}

export function DownloadAppSection({ title }: Props) {
  return (
    <Flex flexDirection="column" alignItems="center" textAlign="center">
      <H2 mb={2}>{title || "Use STEEZY anywhere with our mobile app"}</H2>

      <Flex gap="8px">
        <a href="https://itunes.apple.com/us/app/steezy-studio/id1296001664">
          <AppStoreBadge className={styles.appBadge} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=co.steezy.app">
          <GooglePlayBadge className={styles.appBadge} />
        </a>
      </Flex>

      <Div mt={4} mb={2}>
        <P2>
          Scan the QR code below using the camera on your mobile device to
          download the app:
        </P2>
      </Div>

      <div className={styles.qrCodeContainer}>
        <img
          className={styles.qrCode}
          src={`${env(
            "PUBLIC_ASSET_PATH"
          )}/qr-codes/STEEZY_APP_DOWNLOAD_QR_CODE.png`}
          alt="STEEZY App Download QR Code"
        />
      </div>
    </Flex>
  );
}
