// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__modal--krH2A{max-width:500px;width:90%;padding:32px;border-radius:8px;position:relative}", "",{"version":3,"sources":["webpack://./src/app/components/DownloadAppModal/styles.module.scss"],"names":[],"mappings":"AAEA,6BACE,eAAA,CACA,SAAA,CACA,YAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":["@use \"../../../styles/_variables\" as *;\n\n.modal {\n  max-width: 500px;\n  width: 90%;\n  padding: 32px;\n  border-radius: 8px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles-module__modal--krH2A"
};
export default ___CSS_LOADER_EXPORT___;
