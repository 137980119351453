import React from "react";
import styled from "styled-components";
import {
  BorderProps,
  LayoutProps,
  SpaceProps,
  border,
  layout,
  space,
  system,
} from "styled-system";

interface ImgWrapperProps extends BorderProps, LayoutProps, SpaceProps {
  objectFit?: string;
}

const ImgWrapper = styled.img<ImgWrapperProps>`
  ${layout}
  ${border}
  ${space}
  ${system({
    objectFit: true,
  })}
`;

interface ImgProps extends ImgWrapperProps {
  src: string;
  placeholderSrc?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  alt?: string;
}

const Img: React.FC<ImgProps> = ({
  placeholderSrc,
  src,
  alt,
  ...rest
}: ImgProps) => {
  const [loading, setLoading] = React.useState(true);

  return (
    <>
      {loading && <ImgWrapper src={placeholderSrc} {...rest} />}
      <ImgWrapper
        src={src}
        alt={alt}
        onLoad={() => setLoading(false)}
        {...rest}
      />
    </>
  );
};

export default Img;
