import {
  useGetNotificationsLazyQuery,
  useHasNewNotificationsQuery,
  Notification,
  NotificationSourceType,
} from "services/graphql";
import { useHistory } from "react-router";
import { deconstructSourceParamsCsv } from "./helpers";

export function useGetNotifications() {
  const perPage = 10;
  const [
    callGetNotificationsQuery,
    { data: notificationData, error, loading, fetchMore },
  ] = useGetNotificationsLazyQuery({
    variables: {
      pagination: {
        first: perPage,
      },
    },
    fetchPolicy: "cache-and-network", // to update loading when fetching more
  });
  const {
    data: hasNewNotificationData,
    refetch: refetchHasNewNotifications,
  } = useHasNewNotificationsQuery({
    pollInterval: 30000,
  });

  const notifications = notificationData?.me?.notifications?.edges?.map(
    edge => edge.node
  );
  const pageInfo = notificationData?.me?.notifications?.pageInfo;

  const fetchMoreNotifications = async () => {
    await fetchMore({
      variables: {
        pagination: {
          first: perPage,
          after: pageInfo?.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousEntry = previousResult.me?.notifications;
        const newEdges = fetchMoreResult.me?.notifications.edges;

        return {
          me: {
            ...previousResult.me,
            notifications: {
              ...fetchMoreResult.me?.notifications,
              edges: [...previousEntry.edges, ...newEdges],
            },
          },
        };
      },
    });
    refetchHasNewNotifications();
  };

  const getNotifications = async () => {
    await callGetNotificationsQuery();
    refetchHasNewNotifications();
  };

  return {
    hasNewNotifications: hasNewNotificationData?.me?.hasNewNotifications,
    getNotifications,
    notifications,
    notificationsPageInfo: pageInfo,
    notificationsError: error,
    areNotificationsLoading: loading,
    fetchMoreNotifications,
  };
}

export function useRedirectToNotficationSource(
  notification: Partial<Notification>
) {
  const sourceParamsObject = deconstructSourceParamsCsv(
    notification.sourceParamsCSV
  );
  const history = useHistory();
  const sourceId = sourceParamsObject?.sourceId;
  let redirectPath = "";

  if (
    !sourceId &&
    notification.sourceType !== NotificationSourceType.BadgeEarned
  ) {
    return {
      redirectToNotificationSource: () => {},
    };
  }

  if (notification.sourceType === NotificationSourceType.UgcReactionLike) {
    redirectPath = `/post/${sourceId}`;
  }

  if (notification.sourceType === NotificationSourceType.UgcComment) {
    // @TODO: Potentially autoscroll user to the comment
    redirectPath = `/post/${sourceId}`;
  }

  if (notification.sourceType === NotificationSourceType.UgcCommentReply) {
    // @TODO: Potentially autoscroll user to the reply in comment thread
    redirectPath = `/post/${sourceId}`;
  }

  if (notification.sourceType === NotificationSourceType.BadgeEarned) {
    const challengeId = sourceParamsObject?.challengeId;
    if (challengeId) {
      redirectPath = "/achievements";
      // @TODO: Add back in when we have challenges
      // redirectPath = `/challenge/${challengeId}`;
    } else {
      redirectPath = "/achievements";
    }
  }

  return {
    redirectToNotificationSource: () => history.push(redirectPath),
  };
}
