import { lighten } from "polished";

const monochrome = [
  "#FFFFFF", // 0
  "#EBEFEF", // 1
  "#C8CBCB", // 2
  "#A5A8A8", // 3
  "#848787", // 4
  "#656666", // 5
  "#474848", // 6
  "#2B2C2C", // 7
  "#111111", // 8
  "#222222", // 9
  "#000000", // 10
  "#EAEEEE", // 11
];

const champYellow = [
  "#FFE08F", // 0
  "#FFD66B", // 1
  "#FFCC47", // 2
  "#FFC224", // 3
  "#FFB800", // 4
  "#DB9E00", // 5
  "#B88500", // 6
  "#946B00", // 7
  "#705100", // 8
];

const redMercedes = [
  "#EB9E9E", // 0
  "#E57F7F", // 1
  "#DF6060", // 2
  "#D84141", // 3
  "#D22222", // 4
  "#B51D1D", // 5
  "#971818", // 6
  "#7A1414", // 7
  "#5C0F0F", // 8
];

const cleanMint = [
  "#C8F9DB", // 0
  "#B6F7D0", // 1
  "#A4F5C5", // 2
  "#93F3B9", // 3
  "#81F1AE", // 4
  "#6FCF96", // 5
  "#5DAE7D", // 6
  "#4B8C65", // 7
  "#396A4D", // 8
];

const greys = ["#EBEFEF", "#B4B7B7", "#444444"];
const paypal = ["#009CDE", lighten(0.1, "#009CDE")]; // [base, hover]
const primary = ["#0B79FB", lighten(0.1, "#0B79FB")]; // [base, hover]

const colors = {
  // Base colors
  black: monochrome[8], // for quicker access
  white: monochrome[0], // for quicker access
  blue: "#0B79FB",
  blueberry: "#513AE3",
  bulbaGreen: cleanMint[4],
  mintGreen: cleanMint[4],
  cleanMint,
  champYellow,
  facebook: "#3D5A98",
  google: "#DE5246",
  googleHover: "#F5F5F5",
  gold: champYellow[4],
  greys,
  twitter: "#2DAAE1",
  monochrome,
  magenta: "#B515B8",
  orange: "#EC6125",
  redMercedes,
  sky: "#35B6ED",
  yellow: champYellow[4],

  // Family colors
  familyBlue: "#5A86DF",
  familyOrange: "#EE911D",
  familyPurple: "#9F36C8",
  familyYellow: "#F7BC00",
  familyTeal: "#0C4134",

  // Legacy colors
  advanced: "#F82B60",
  beginner: "#5CB85C",
  blueTransparent: "rgba(74, 144, 226, 0.4)",
  brown: "#8A6D3B",
  danger: "#EB5A46",
  darkGrey: "#434343", // TODO: Deprecate
  green: "#00A779",
  grey: "#BCBABA",
  intermediate: "#FCB400",
  lightGrey: "#EBEFEF", // TODO: Deprecate
  limeGreen: "#7ED321",
  medGrey: "#9B9B9B", // TODO: Deprecate
  neutralGray: "#F6F8F9", // TODO: Deprecate
  paypal,
  primary,
  red: "#c10000",
  spotify: "#1CB954",
  success: "#58A107",
  warning: "#FFBE27",
  youtube: "#FF0000",
};

// Create type from the colors object
export type ColorName = keyof typeof colors;

// Export the colors object as default
export default colors;
