import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { color as colorSystem, layout, system } from "styled-system";

interface WrapperProps {
  bg?: string;
  height?: string | number;
  opacity?: number;
}

interface ProgressProps {
  color?: string;
  width: string;
}

interface ProgressBarProps {
  className?: string;
  color?: string;
  percent?: number;
  bg?: string;
  height?: string | number;
  opacity?: number;
}

const Wrapper = styled.div.attrs<WrapperProps>(({ bg, height }) => ({
  height: height || "20px",
  bg: bg || "darkGrey",
}))`
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  ${layout}
  ${colorSystem}
  ${system({
    opacity: true,
  })}
`;

const Progress = styled.div<ProgressProps>`
  float: left;
  height: 100%;
  background-color: ${({ color, theme }) =>
    (color && theme.colors[color as keyof DefaultTheme["colors"]]) ||
    color ||
    theme.colors.primary[0]};
  transition: width 0.6s ease;
  ${layout}
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
  className = "",
  color,
  percent = 0,
  ...rest
}) => (
  <Wrapper className={className} {...rest}>
    <Progress color={color} width={`${percent || 0}%`} />
  </Wrapper>
);

export default ProgressBar;
