import {
  useGetUsersBadgesQuery,
  useGetClassCompletedAchievementBadgesQuery,
} from "services/graphql";

export function useUserAchievements({ username }: { username: string }) {
  const { data, loading, error, refetch } = useGetUsersBadgesQuery({
    variables: {
      username,
    },
  });

  const sortedBadgesByYear = data?.badgesByYear
    ? [...data.badgesByYear]
        .sort((a, b) => b.year - a.year)
        .map(yearGroup => ({
          ...yearGroup,
          userBadges: [...yearGroup.userBadges].sort(
            (a, b) =>
              new Date(b.earnedAt).getTime() - new Date(a.earnedAt).getTime()
          ),
        }))
    : [];

  return {
    badgesByYear: sortedBadgesByYear,
    loading,
    error,
    refetch,
  };
}

export function useCalculateClassCompletedBadges() {
  const { data, loading, error } = useGetClassCompletedAchievementBadgesQuery();
  return {
    earnedBadges: data?.getClassCompletedAchievementBadges?.earnedBadges || [],
    newBadges: data?.getClassCompletedAchievementBadges?.newBadges || [],
    loading,
    error,
  };
}
