import React from "react";
import { Helmet } from "react-helmet-async";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { Container, CenterWrapper } from "app/components/RouteComponents";
import moment from "moment";
import { useGetUserProfile } from "hooks/User";
import { NotFound } from "app/components/NotFound";
import { ReactSVG } from "react-svg";
import { H1, H2, H5, P2 } from "app/components/Typography";
import Div from "app/components/Div";
import { DownloadAppSection } from "app/components/DownloadAppSection";
import styles from "./styles.module.scss";
import { useCalculateClassCompletedBadges, useUserAchievements } from "./hooks";

interface AchievementsProps {
  username: string;
}

export default function Achievements(): JSX.Element {
  const [profileData, loading, error] = useGetUserProfile();
  const { username: profileUsername } = profileData;

  useCalculateClassCompletedBadges();

  if (loading) {
    return <LoaderCentered />;
  }

  if (error) {
    return <NotFound />;
  }

  return <AchievementsInner username={profileUsername} />;
}

function AchievementsInner({ username }: AchievementsProps): JSX.Element {
  const { badgesByYear, loading, error, refetch } = useUserAchievements({
    username,
  });

  if (loading) {
    return <LoaderCentered />;
  }

  if (error) {
    return (
      <CenterWrapper>
        <Container>
          <Div>Error loading achievements. Click to retry.</Div>
          <button
            type="button"
            className={styles.retryButton}
            onClick={() => refetch()}
          >
            Retry
          </button>
        </Container>
      </CenterWrapper>
    );
  }

  if (badgesByYear.length === 0) {
    return (
      <CenterWrapper>
        <Container>
          <H1 mb={3}>Achievements</H1>
          <Div mt={3} className={styles.downloadSection}>
            <DownloadAppSection title="Start earning achievements by downloading our app and completing challenges!" />
          </Div>
        </Container>
      </CenterWrapper>
    );
  }

  return (
    <CenterWrapper>
      <Helmet title="Achievements | Online Dance Classes and Tutorials" />
      <Container>
        <H1 mb={3}>Achievements</H1>
        {badgesByYear.map(yearGroup => (
          <Div key={yearGroup.year} className={styles.yearSection}>
            <H2 mb={3}>{yearGroup.year}</H2>
            <Div className={styles.achievementWrapper}>
              {yearGroup.userBadges.map(item => {
                if (!item.badge) {
                  return null;
                }

                return (
                  <Div
                    key={item.badge.name}
                    className={styles.achievementContainer}
                  >
                    {item.badge.activeImageUrl && (
                      <ReactSVG
                        src={item.badge.activeImageUrl}
                        className={styles.badgeImage}
                      />
                    )}
                    <H5 textAlign="center">
                      {item.badge.challenge
                        ? item.badge.challenge.title
                        : item.badge.name}
                    </H5>
                    <P2 className={styles.badgeDate}>
                      {moment(item.earnedAt).format("MMM D, YYYY")}
                    </P2>
                  </Div>
                );
              })}
            </Div>
          </Div>
        ))}
      </Container>
    </CenterWrapper>
  );
}
