import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import AuthenticatedRoute from "app/components/RouteComponents/AuthenticatedRoute";
import { ChallengesFeatureRoute } from "app/components/Challenges";
import RedirectWithParams from "app/components/RouteComponents/RedirectWithParams";
import SubscribedRoute from "app/components/RouteComponents/SubscribedRoute";
import UnauthenticatedRoute from "app/components/RouteComponents/UnauthenticatedRoute";
import { Routes as R } from "constants/routes";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { useAreNotificationsAvailable } from "hooks/useFeatureFlags";
import queryString from "query-string";
import { useGetUserIsAnonymous } from "modules/selectors";
import { AsyncComponent } from "./AsyncComponent";
import NotFound from "./NotFound";

const redirectMapFamilyModeEnabled = {
  [R.account]: R.library__classes,
  [R.achievements]: R.library__classes,
  [R.billing_past_due]: R.library__classes,
  [R.challenges]: R.library__classes,
  [R.checkout]: R.library__classes,
  [R.community]: R.library__classes,
  [R.dashboard]: R.library__classes,
  [R.history]: R.library__classes,
  [R.library__categories]: R.library__classes,
  [R.library__instructors]: R.library__classes,
  [R.library__programs]: R.library__classes,
  [R.party]: R.library__classes,
  [R.playlists]: R.playlists,
  [R.post]: R.library__classes,
  [R.profile]: R.library__classes,
  [R.programs]: R.library__classes,
  [R.results]: R.library__classes,
  [R.saved]: R.library__classes,
  [R.unlocked_classes]: R.library__classes,
  [R.upgrade]: R.library__classes,
};

interface Props {
  isModal?: boolean;
  previousLocation?: any;
}

export function Routes({ isModal, previousLocation = {} }: Props) {
  const location = useLocation();
  const { isFamilyModeOn } = useFamilyModeSetting();
  const isAnonymous = useGetUserIsAnonymous();
  const areNotificationsFeaturesAvailable = useAreNotificationsAvailable();

  return (
    <>
      <Switch location={isModal ? previousLocation : location}>
        {isFamilyModeOn &&
          createRedirects(redirectMapFamilyModeEnabled, { exact: false })}
        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/library" to="/library/classes" />
        <Redirect exact from={R.history} to={R.history__all} />
        <Redirect exact from={R.saved} to={R.saved__classes} />
        <Route
          exact
          path={R.playlists}
          render={() => {
            if (isAnonymous) {
              return <Redirect to={R.playlists__steezy} />;
            }

            return <Redirect to={R.playlists__created} />;
          }}
        />
        <Redirect exact from="/account" to="/account/settings" />
        {/* Redirecting /all-classes to ensure marketing links don't break */}
        <RedirectWithParams from="/all-classes" to="/results/classes" />
        <Redirect
          exact
          from="/categories/:categorySlug"
          to="/results/categories/:categorySlug"
        />
        <Redirect
          exact
          from="/results/categories/:categorySlug"
          to={{
            // Passing state through redirect since we're adding state on userSelectsCategoryAction in module/content.js
            pathname: "/results/categories/:categorySlug/classes",
            state: location.state,
          }}
        />
        <Redirect
          exact
          from="/categories/:categorySlug/classes"
          to="/results/categories/:categorySlug/classes"
        />
        <Redirect
          exact
          from="/categories/:categorySlug/programs"
          to="/results/categories/:categorySlug/programs"
        />
        <RedirectWithParams from="/activate-trial" to="/checkout" />
        <UnauthenticatedRoute
          exact
          path="/login"
          children={<AsyncComponent path="Login" />}
        />
        <Route
          exact
          path="/register"
          children={<AsyncComponent path="Register" />}
        />
        <ChallengesFeatureRoute
          path={R.profile__username}
          children={<AsyncComponent path="Profile/User" />}
        />
        <ChallengesFeatureRoute
          path={R.profile}
          children={<AsyncComponent path="Profile" />}
        />
        <AuthenticatedRoute
          exact
          path={R.account__settings}
          children={<AsyncComponent path="Account/Settings" />}
        />
        <AuthenticatedRoute
          exact
          path={R.account__preferences}
          children={<AsyncComponent path="Account/Preferences" />}
        />
        <AuthenticatedRoute
          exact
          path={R.account__subscription}
          children={<AsyncComponent path="Account/Subscription" />}
        />
        <AuthenticatedRoute
          exact
          path={R.account__subscription__switch_plan}
          children={<AsyncComponent path="Account/Subscription/SwitchPlan" />}
        />
        <AuthenticatedRoute
          exact
          path={R.account__cancel}
          children={<AsyncComponent path="Account/Cancel" />}
        />
        {areNotificationsFeaturesAvailable && (
          <AuthenticatedRoute
            exact
            path={R.account__manage_notifications}
            children={<AsyncComponent path="Account/Notifications" />}
          />
        )}
        <AuthenticatedRoute
          exact
          path={R.account__family}
          children={<AsyncComponent path="Account/Family" />}
        />
        <AuthenticatedRoute
          exact
          path="/achievements"
          children={<AsyncComponent path="Achievements" />}
        />
        <AuthenticatedRoute
          exact
          path="/checkout"
          children={<AsyncComponent path="Checkout" />}
        />
        <AuthenticatedRoute
          path={R.history__menu_option}
          children={<AsyncComponent path="History" />}
        />
        <AuthenticatedRoute
          path="/dashboard"
          children={<AsyncComponent path="Dashboard" />}
        />
        <AuthenticatedRoute
          path="/schedule"
          children={<AsyncComponent path="Schedule" />}
        />
        <AuthenticatedRoute
          path={R.saved__menu_option}
          children={<AsyncComponent path="Saved" />}
        />
        <AuthenticatedRoute
          path="/purchased"
          children={<AsyncComponent path="Purchased" />}
        />
        <Route
          path={R.playlists__steezy}
          children={<AsyncComponent path="Playlists/SteezyPlaylists" />}
        />
        <AuthenticatedRoute
          path={R.playlists__created}
          children={<AsyncComponent path="Playlists/CreatedPlaylists" />}
        />
        <AuthenticatedRoute
          path={R.playlists__following}
          children={<AsyncComponent path="Playlists/FollowedPlaylists" />}
        />
        <AuthenticatedRoute
          path={R.playlists__weekly_recommendations}
          children={
            <AsyncComponent path="Playlists/PlaylistPage/WeeklyRecommendations" />
          }
        />
        <Route
          path={R.playlists__id}
          children={<AsyncComponent path="Playlists/PlaylistRoute" />}
        />
        <AuthenticatedRoute
          path="/unlocked-classes"
          children={<AsyncComponent path="UnlockedClasses" />}
        />
        <AuthenticatedRoute
          path="/billing-past-due"
          children={<AsyncComponent path="BillingPastDue" />}
        />
        <ChallengesFeatureRoute
          exact
          path="/community"
          children={<AsyncComponent path="Community" />}
        />
        <ChallengesFeatureRoute
          exact
          path="/challenges/:id"
          children={<AsyncComponent path="Community/ChallengesInfoScreen" />}
        />
        <ChallengesFeatureRoute
          exact
          path="/class/:classNumber/posts"
          children={<AsyncComponent path="Class/UserPosts" />}
        />
        <Route
          exact
          path={R.class}
          render={() => {
            const { class_id, ...rest } = queryString.parse(location.search);

            if (class_id) {
              const classUrl = queryString.stringifyUrl({
                url: `${R.class}/${class_id}`,
                query: rest,
              });
              return <Redirect to={classUrl} />;
            }

            return <Redirect to={R.library__classes} />;
          }}
        />
        <Route
          exact
          path={R.class}
          render={() => {
            const { class_id, ...rest } = queryString.parse(location.search);

            if (class_id) {
              const classUrl = queryString.stringifyUrl({
                url: `${R.class}/${class_id}`,
                query: rest,
              });
              return <Redirect to={classUrl} />;
            }

            return <Redirect to={R.library__classes} />;
          }}
        />{" "}
        <Route
          exact
          path="/deep-link-2"
          render={() => <DeepLinkComponent2 />}
        />
        <Route
          exact
          path="/deep-link-3"
          render={() => <DeepLinkComponent3 />}
        />
        <Route
          exact
          path={R.class__id}
          render={props => <AsyncComponent {...props} path="Class" />}
        />
        <SubscribedRoute
          exact
          path="/upgrade"
          children={<AsyncComponent path="Upgrade" />}
        />
        <Route
          exact
          path="/library"
          children={<AsyncComponent path="Library" />}
        />
        <Route
          exact
          path="/library/:menuOption"
          children={<AsyncComponent path="Library" />}
        />
        <Route
          exact
          path={R.results__classes}
          children={<AsyncComponent path="Results/Classes" />}
        />
        <Route
          exact
          path={R.results__free_classes}
          children={<AsyncComponent path="Results/FreeClasses" />}
        />
        <Route
          exact
          path={R.results__basic_classes}
          children={<AsyncComponent path="Results/BasicClasses" />}
        />
        <Route
          exact
          path={R.results__programs}
          children={<AsyncComponent path="Results/Programs" />}
        />
        <Route
          exact
          path={R.results__instructors}
          children={<AsyncComponent path="Results/Instructors" />}
        />
        <Route
          exact
          path={R.results__categories__categorySlug__classes}
          children={<AsyncComponent path="Results/Categories/Classes" />}
        />
        <Route
          exact
          path={R.results__categories__categorySlug__programs}
          children={<AsyncComponent path="Results/Categories/Programs" />}
        />
        <Route
          exact
          path="/password-reset"
          children={<AsyncComponent path="PasswordReset" />}
        />
        <Route
          path="/programs/:slug"
          children={<AsyncComponent path="ProgramsV2" />}
        />
        <Route exact path="/party" children={<AsyncComponent path="Party" />} />
        <Route
          exact
          path="/programs"
          children={<AsyncComponent path="Programs" />}
        />
        <Route
          exact
          path="/library/instructors/:slug"
          render={props => (
            <AsyncComponent {...props} path="Library/Instructors/Profile" />
          )}
        />
        <Route
          exact
          path="/started-trial"
          children={<AsyncComponent path="StartedTrial" />}
        />
        <Route
          exact
          path="/created-account"
          children={<AsyncComponent path="CreatedAccount" />}
        />
        <Route
          exact
          path={R.post}
          render={() => {
            const { post_id, ...rest } = queryString.parse(location.search);

            if (post_id) {
              const postUrl = queryString.stringifyUrl({
                url: `${R.post}/${post_id}`,
                query: rest,
              });
              return <Redirect to={postUrl} />;
            }

            return <Redirect to="/community" />;
          }}
        />
        <Route
          exact
          path={R.post__id}
          children={<AsyncComponent path="UserPost" />}
        />
        <Route exact path="/not-found" component={NotFound} />
        <Redirect to="/not-found" />
      </Switch>
    </>
  );
}

function createRedirects(redirectMap: any, options: any) {
  return Object.entries(redirectMap).map(([from, to]) => {
    return (
      <Redirect
        key={`${from}TO${to}`}
        exact={options.exact}
        from={from}
        to={to}
      />
    );
  });
}

function DeepLinkComponent2() {
  useEffect(() => {
    window.location.assign(
      "steezy://?screen=user_post&post_id=8b531f48-cda4-4e87-ae7f-506ac5d68d53"
    );
  }, []);

  return <>GET REDIRECTED</>;
}

function DeepLinkComponent3() {
  useEffect(() => {
    window.location.assign(
      "steezy://?screen=user_post&post_id=e9cbb7e0-0d44-4326-8d49-3e804ac76c55"
    );
  }, []);

  return <>GET REDIRECTED</>;
}
