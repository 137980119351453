import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useGetCommunityAccess } from "hooks/User";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useGetUserIsAnonymous } from "modules/selectors";

const ChallengesFeatureRoute = ({ children, ...rest }) => {
  const isAnonymous = useGetUserIsAnonymous();
  const [hasCommunityAccess, loading] = useGetCommunityAccess();

  return (
    <Route
      {...rest}
      render={props => {
        if (isAnonymous) {
          return <Redirect to={`/login?redirect=${props.location.pathname}`} />;
        }

        if (loading) {
          return <LoaderCentered />;
        }

        if (!hasCommunityAccess) {
          return <Redirect to="/dashboard" />;
        }

        return <>{children}</>;
      }}
    />
  );
};

ChallengesFeatureRoute.defaultProps = {
  location: {},
};

ChallengesFeatureRoute.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
};

export default ChallengesFeatureRoute;
