import { useQuery } from "@apollo/client";
import { Card } from "app/components/ProgramCard/Card";
import { ProgramCardSkeleton } from "app/components/ProgramCard/ProgramCardSkeleton";
import { GET_PROGRAM_V2_CORE } from "graphql/queries";
import useToggleSaveProgram from "hooks/ProgramsV2/useToggleSaveProgram";
import { isEmpty } from "lodash";
import { userSelectsProgramAction } from "modules/content";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

const ProgramCard = ({
  selectedFrom,
  data,
  userSelectsProgram,
  onMouseEnter,
  onClick,
  disableClick,
}) => {
  const toggleSaveProgramMutation = useToggleSaveProgram();
  const goToProgram = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    userSelectsProgram({
      programSlug: data.slug,
      component: selectedFrom,
    });
  };

  const [programData, setProgramData] = useState(null);
  const { data: { getProgramV2 } = {}, loading } = useQuery(
    GET_PROGRAM_V2_CORE,
    {
      variables: { slug: data.slug },
    }
  );

  useEffect(() => {
    if (getProgramV2 && !isEmpty(getProgramV2)) {
      setProgramData(getProgramV2);
    }
  }, [getProgramV2]);

  if (!programData && !loading) {
    return <></>;
  }
  if (loading) {
    return <ProgramCardSkeleton />;
  }

  const props = {
    contentType: "program",
    totalClassCount: programData.totalClassesCount,
    isProgressCompleted: Boolean(programData.progress?.completedDate),
    contentData: {
      ...programData,
      completed_classes: programData.progress?.completedClassesCount,
    },
    thumbnailSrc: `${programData.content?.assets.thumbnailURL}?ar=1.777777778:1&fit=crop`,
    thumbnailPlaceholderSrc: `${programData.content?.assets.thumbnailURL}?ar=1.777777778:1&fit=crop&blur=200&px=16&auto=format`,
    isSaved: programData.isSaved,
    onMouseEnter,
    onClick,
    disableClick,
    selectedFrom,
  };

  Object.keys(props).forEach(
    key => props[key] === undefined && delete props[key]
  );

  return (
    <Card
      {...props}
      goToContent={goToProgram}
      saveUserContent={() => {
        toggleSaveProgramMutation({
          component: selectedFrom,
          variables: {
            programSlug: data.slug,
            willSave: !programData.isSaved,
          },
          data,
        });
      }}
    />
  );
};

const mapDispatchToProps = {
  userSelectsProgram: userSelectsProgramAction,
};

ProgramCard.defaultProps = {
  scaleSize: 1.25,
  selectedFrom: "ProgramCard",
};

ProgramCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
  userSelectsProgram: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onClick: PropTypes.func,
  scaleSize: PropTypes.number,
  selectedFrom: PropTypes.string,
  disableClick: PropTypes.bool,
};

export default compose(connect(null, mapDispatchToProps))(ProgramCard);
