import React from "react";
import { Notification, NotificationSourceType } from "services/graphql";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Avatar from "app/components/Avatar";
import { NotificationComment, NotificationLike } from "app/components/Icon";
import Img from "app/components/Img";
import { env } from "helpers/env/get";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { deconstructSourceParamsCsv } from "./helpers";
import { useRedirectToNotficationSource } from "./hooks";
import { Wrapper, Span, Header, Body, Icon as StyledIcon } from "./styles";

const NOTIFICATION_ICON_MAP = {
  UGCComment: NotificationComment,
  UGCCommentReply: NotificationComment,
  UGCReactionLike: NotificationLike,
};

function isUgcNotification(sourceType: NotificationSourceType) {
  return [
    NotificationSourceType.UgcComment,
    NotificationSourceType.UgcCommentReply,
    NotificationSourceType.UgcReactionLike,
  ].includes(sourceType);
}

interface Props {
  notification: Partial<Notification>;
}

const StyledReactSVG = styled(ReactSVG)`
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
`;

function NotificationThumbnail({
  thumbnailUrl,
  sourceType,
}: {
  thumbnailUrl: string;
  sourceType: NotificationSourceType;
}) {
  if (!thumbnailUrl) {
    return null;
  }

  const thumbnailStyles = {
    height: "50px",
    width: "26px",
    overflow: "hidden",
    position: "relative" as const,
    borderRadius: "2px",
  };

  if (sourceType === NotificationSourceType.BadgeEarned) {
    return (
      <Flex {...thumbnailStyles}>
        <StyledReactSVG src={thumbnailUrl} />
      </Flex>
    );
  }

  return (
    <Flex {...thumbnailStyles}>
      <Img
        src={thumbnailUrl}
        alt="Post Thumbnail"
        placeholderSrc={`${env("PUBLIC_ASSET_PATH")}/challenges/black.jpg`}
      />
    </Flex>
  );
}

export function NotificationItem({ notification }: Props) {
  const { redirectToNotificationSource } = useRedirectToNotficationSource(
    notification
  );
  const sourceParamsObject = deconstructSourceParamsCsv(
    notification.sourceParamsCSV
  );
  const thumbnailUrl = sourceParamsObject?.thumbnailUrl;
  const NotificationTypeIcon =
    NOTIFICATION_ICON_MAP[
      notification.sourceType as keyof typeof NOTIFICATION_ICON_MAP
    ];

  let avatarImageUrl;

  if (isUgcNotification(notification.sourceType)) {
    avatarImageUrl = notification.senderProfile?.photoURL;
  } else if (notification.sourceType === NotificationSourceType.BadgeEarned) {
    avatarImageUrl = `${env(
      "PUBLIC_ASSET_PATH"
    )}/steezy-avatar-image.png?w=240`;
  }

  return (
    <Wrapper
      isSeen={notification.seen}
      onClick={() => redirectToNotificationSource()}
    >
      <Div position="relative">
        <Avatar
          src={avatarImageUrl}
          alt="Profile Avatar"
          height="50px"
          width="50px"
        />
        {NotificationTypeIcon && (
          <StyledIcon as={NotificationTypeIcon} isSeen={notification.seen} />
        )}
      </Div>
      <Flex flexDirection="column">
        <Header>
          {notification.header} <Span>{notification.timeSince}</Span>
        </Header>
        <Body>{notification.message}</Body>
      </Flex>
      <NotificationThumbnail
        thumbnailUrl={thumbnailUrl}
        sourceType={notification.sourceType as NotificationSourceType}
      />
    </Wrapper>
  );
}
