import Flex from "app/components/Flex";
import { NotFound } from "app/components/NotFound";
import ScrollToTopOnMount from "app/components/ScrollToTopOnMount";
import React from "react";
import { Helmet } from "react-helmet-async";
import Loader from "app/components/Loader";
import { useGetHomeData } from "./hooks";
import AuthAlert from "./AuthAlert";
import { ContinueDancing } from "./ContinueDancing";
import { DancePrograms } from "./DancePrograms";
import { DanceWorkouts } from "./DanceWorkouts";
import { FeaturedClasses } from "./FeaturedClasses";
import { SavedClasses } from "./SavedClasses";
import { TodaysClasses } from "./TodaysClasses";
import { WarmupsAndStretches } from "./WarmupsAndStretches";
import { MovesAndTechniques } from "./MovesAndTechniques";
import { UserSummary } from "./UserSummary";
import { TrendingClassesWithLevel } from "./TrendingClassesWithLevel";
import { PersonalizedPlaylist } from "./PersonalizedPlaylist";
import { FeaturedPlaylists } from "./FeaturedPlaylists";
import { Moves } from "./Moves";
import { TechniqueAndFundamentals } from "./TechniqueAndFundamentals";
import { WarmupsStretchesAndCooldowns } from "./WarmsupsStretchesAndCooldowns";
import { Workouts } from "./Workouts";
import { BecauseYouLiked1, BecauseYouLiked2 } from "./BecauseYouLiked";
import { PromotedChallenges } from "./PromotedChallenges";

const Dashboard = () => {
  const {
    todaysClasses,
    continueDancing,
    dancePrograms,
    savedClasses,
    loading,
    error,
  } = useGetHomeData();

  const dashboardItems = [
    <UserSummary />,
    <PromotedChallenges />,
    <FeaturedPlaylists />,
    <FeaturedClasses />,
    <TrendingClassesWithLevel />,
    <WarmupsStretchesAndCooldowns />,
    <WarmupsAndStretches />,
    <Moves />,
    <TechniqueAndFundamentals />,
    <MovesAndTechniques />,
    <SavedClasses loading={loading} data={savedClasses} />,
    <PersonalizedPlaylist />,
    <ContinueDancing loading={loading} data={continueDancing} />,
    <DancePrograms loading={loading} data={dancePrograms} />,
    <BecauseYouLiked1 />,
    <DanceWorkouts />,
    <BecauseYouLiked2 />,
    <Workouts />,
    todaysClasses?.length > 0 && (
      <TodaysClasses loading={loading} data={todaysClasses} />
    ),
  ].filter(Boolean);

  return (
    <>
      <ScrollToTopOnMount />
      <Helmet title="Home | Online Dance Classes and Tutorials" />
      <AuthAlert />
      {error ? (
        <NotFound />
      ) : (
        <Flex flexDirection="column" width="100%" margin="0 auto" pb="75px">
          {dashboardItems.map((Component, index) => (
            <React.Suspense key={index} fallback={<Loader />}>
              {Component}
            </React.Suspense>
          ))}
        </Flex>
      )}
    </>
  );
};

export default Dashboard;
