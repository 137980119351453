import React from "react";
import { ProgressBar } from "app/components/ProgressBar";
import { SkeletonText, SkeletonTextLoader } from "app/components/SkeletonText";
import { SkeletonDivAnimation } from "app/components/SkeletonAnimation";
import styles from "./styles.module.scss";
import Div from "../Div";

export function ChallengeProgressCardSkeleton() {
  return (
    <div className={styles.card}>
      <Div
        width="48px"
        height="48px"
        borderRadius="100%"
        mr="16px"
        overflow="hidden"
        bg="monochrome.2"
      >
        <SkeletonDivAnimation width="100%" height="100%" />
      </Div>
      <div className={styles.content}>
        <SkeletonText width="200px" height="24px">
          <SkeletonTextLoader width="100%" height="100%" />
        </SkeletonText>
        <SkeletonText width="150px" height="20px">
          <SkeletonTextLoader width="100%" height="100%" />
        </SkeletonText>
        <ProgressBar
          className={styles.progressBar}
          color="primary"
          percent={0}
        />
        <SkeletonText width="100px" height="20px">
          <SkeletonTextLoader width="100%" height="100%" />
        </SkeletonText>
      </div>
      <Div
        width="32px"
        height="32px"
        ml="16px"
        bg="monochrome.2"
        borderRadius="100%"
        overflow="hidden"
      >
        <SkeletonDivAnimation width="100%" height="100%" borderRadius="50%" />
      </Div>
    </div>
  );
}
