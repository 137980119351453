// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__carouselWrapper--raLFe{width:100%;margin:0 auto;padding:24px 0}.styles-module__sliderWrapper--gjVaz{width:100%;max-width:1600px;margin:0 auto;display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/app/routes/Dashboard/PromotedChallenges/styles.module.scss"],"names":[],"mappings":"AAAA,uCACE,UAAA,CACA,aAAA,CACA,cAAA,CAGF,qCACE,UAAA,CACA,gBAAA,CACA,aAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".carouselWrapper {\n  width: 100%;\n  margin: 0 auto;\n  padding: 24px 0;\n}\n\n.sliderWrapper {\n  width: 100%;\n  max-width: 1600px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carouselWrapper": "styles-module__carouselWrapper--raLFe",
	"sliderWrapper": "styles-module__sliderWrapper--gjVaz"
};
export default ___CSS_LOADER_EXPORT___;
