// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__benefitsContainer--lmCh3{margin-top:12px;display:grid;grid-template-columns:1fr 1fr;gap:6px}.styles-module__benefitsContainer--lmCh3 .styles-module__textContainer--XoxA8{align-items:center;display:flex;text-transform:capitalize;gap:4px}.styles-module__benefitsContainer--lmCh3 .styles-module__textContainer--XoxA8 p{font-style:normal;font-weight:400;line-height:130%;letter-spacing:.14px;font-size:12px}.styles-module__benefitsContainer--lmCh3 .styles-module__icon--JF_p7{width:10px}", "",{"version":3,"sources":["webpack://./src/app/components/SubscribeModal/PlanSelect/BenefitsText/styles.module.scss"],"names":[],"mappings":"AAEA,yCACE,eAAA,CACA,YAAA,CACA,6BAAA,CACA,OAAA,CAEA,8EACE,kBAAA,CACA,YAAA,CACA,yBAAA,CACA,OAAA,CAEA,gFACE,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CACA,cAAA,CAIJ,qEACE,UAAA","sourcesContent":["@use \"../../../../../styles/_variables\" as *;\n\n.benefitsContainer {\n  margin-top: 12px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 6px;\n\n  .textContainer {\n    align-items: center;\n    display: flex;\n    text-transform: capitalize;\n    gap: 4px;\n\n    p {\n      font-style: normal;\n      font-weight: 400;\n      line-height: 130%;\n      letter-spacing: 0.14px;\n      font-size: 12px;\n    }\n  }\n\n  .icon {\n    width: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"benefitsContainer": "styles-module__benefitsContainer--lmCh3",
	"textContainer": "styles-module__textContainer--XoxA8",
	"icon": "styles-module__icon--JF_p7"
};
export default ___CSS_LOADER_EXPORT___;
