/* eslint-disable no-console */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import OneSignal from "react-onesignal";
import env from "helpers/env";
import { useGetUserIsAnonymous } from "modules/selectors";
import { usePushNotificationsFlag } from "hooks/useFeatureFlags";

export function useOneSignal() {
  const { uid } = useSelector(({ auth }: { auth: { uid: string } }) => auth);
  const isAnonymous = useGetUserIsAnonymous(true);
  const isPushNotificationFeatureEnabled = usePushNotificationsFlag();

  useEffect(() => {
    async function runOneSignal() {
      try {
        if (window.location.hostname === "localhost") {
          console.log("Skipping OneSignal initialization on localhost");
          return;
        }

        await OneSignal.init({
          appId: env("PUBLIC_ONE_SIGNAL_APP_ID"),
          allowLocalhostAsSecureOrigin: true,
        });
        console.log("OneSignal Initialized");
        OneSignal.Slidedown.promptPush();
        OneSignal.login(uid);
      } catch (error) {
        console.log("OneSignal Error", error);
      }
    }

    if (isAnonymous) {
      return;
    }

    if (!isPushNotificationFeatureEnabled) {
      return;
    }

    runOneSignal();
  }, [isAnonymous, isPushNotificationFeatureEnabled, uid]);
}
