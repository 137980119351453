import React from "react";
import { Slider } from "app/components/Slider";
import { ChallengeProgressCard } from "app/components/ChallengeProgressCard";
import { ChallengeProgressCardSkeleton } from "app/components/ChallengeProgressCard/ChallengeProgressCardSkeleton";
import { ChallengeCardFragment } from "services/graphql";
import { Section } from "../Section";
import { useGetChallenges } from "./hooks";

export function PromotedChallenges() {
  const { challenges, loading } = useGetChallenges();

  const loadingStateComponent = () => {
    return (
      <Slider
        data={[{}, {}, {}, {}, {}]}
        slideComponent={ChallengeProgressCardSkeleton}
        arrowTopPosition="35%"
        slidesSpacing={24}
        slidesWidth={400}
      />
    );
  };

  if (!loading && !challenges?.length) {
    return null;
  }

  return (
    <Section
      title="Challenges"
      showLoadingState={loading}
      showTitleLoadingState
      loadingStateComponent={loadingStateComponent()}
    >
      <Slider
        data={challenges}
        slideComponent={({
          data: challenge,
        }: {
          data: ChallengeCardFragment;
        }) => <ChallengeProgressCard challenge={challenge} />}
        selectedFrom="Promoted Challenges Carousel"
        arrowTopPosition="35%"
        slidesSpacing={24}
        slidesWidth={400}
      />
    </Section>
  );
}
