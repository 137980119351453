import React from "react";
import styled from "styled-components";
import StyledProgressBar from "app/components/ProgressBar/ProgressBar.styled";
import Icon, { CheckSquareV2, LockedIndicatorV2 } from "app/components/Icon";
import Div from "app/components/Div";

export {
  LabelText,
  ClassTypeLabel,
} from "app/components/ClassCard/Card/styles";

export const LockedLabel = () => {
  return (
    <Div
      backgroundColor="monochrome.10"
      borderRadius="2px"
      width="18px"
      height="18px"
    >
      <Icon color="white" as={LockedIndicatorV2} />
    </Div>
  );
};

export const CompletedIcon = styled(CheckSquareV2)`
  color: ${({ theme }) => theme.colors.white};
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const CARD_RATIO = 130 / 280;

export const ProgressBar = styled(StyledProgressBar)`
  position: absolute;
  bottom: 0;
  height: 6px;
  width: 100%;
`;

export const LinkWrapper = styled.a`
  width: 280px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
`;

export const ClassInfoText = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

export const ThumbnailWrapper = styled.div`
  background: ${({ theme }) => theme.colors.darkGrey};
  position: absolute;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  top: 0;
  mask-image: -webkit-radial-gradient(white, black);
`;

export const ThumbnailContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: ${CARD_RATIO * 100}%;
  position: relative;
`;
