import transition from "styled-transition-group";
import ProgressBar from "app/components/ProgressBar/ProgressBar.styled";
import { size, space, flexbox, grid } from "styled-system";
import Div from "app/components/Div";
import Text from "app/components/Text";
import { P2 } from "app/components/Typography";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export const CARD_PADDING = 16;

const WrappingP2 = styled(P2)`
  ${({ theme }) => theme.mediaQueries.ltsm} {
    width: 175px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ThumbnailWrapper = styled(Div).attrs({
  height: "100%",
  bg: "darkGrey",
  position: "relative",
  overflow: "hidden",
})`
  border-radius: 8px;
  mask-image: -webkit-radial-gradient(white, black);
`;

export const OverlayShadow = styled(Div).attrs(({ height }) => {
  return {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: height || "50%",
  };
})``;

export const ContentAttribute = ({ label, value, ...rest }) => (
  <WrappingP2 {...rest}>
    {label && `${label}:${" "}`}
    <Text as="span" fontWeight="bold">
      {value}
    </Text>
  </WrappingP2>
);

ContentAttribute.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export const CARD_RATIO = 9 / 16;

export const Container = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-width: calc(100% + 1px);
  min-height: calc(100% + 1px);
  position: absolute;
  top: 0;
  left: 0;

  ${({ hasShadow, isActive }) =>
    hasShadow &&
    (isActive
      ? `
      box-shadow: 6px 6px 0 #0857B5, 12px 12px 0 #0B79FB;
    `
      : `
    box-shadow: 6px 6px 0 #ACAEAE, 12px 12px 0 #CBCECE;
  `)}
`;

export const Wrapper = styled.a`
  position: relative;
  width: 100%;
  cursor: pointer;
  padding-top: ${`${CARD_RATIO * 100}%`};

  ${space};
  ${flexbox};
  ${grid};
  ${size};
`;

export const FadeOutThumbnail = transition.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;

  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }
`;

export const LockOverlay = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
`;

export const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  bottom: 0;
  height: 6px;
  width: 100%;

  ${({ hide }) => hide && "opacity: 0"};
`;
