// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__appBadge--OQVys{width:auto;height:50px}.styles-module__qrCodeContainer--zaNVw{width:150px;height:150px;margin:0 auto}.styles-module__qrCode--LUojh{width:100%;height:100%;object-fit:contain}", "",{"version":3,"sources":["webpack://./src/app/components/DownloadAppSection/styles.module.scss"],"names":[],"mappings":"AAEA,gCACE,UAAA,CACA,WAAA,CAGF,uCACE,WAAA,CACA,YAAA,CACA,aAAA,CAGF,8BACE,UAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":["@use \"../../../styles/_variables\" as *;\n\n.appBadge {\n  width: auto;\n  height: 50px;\n}\n\n.qrCodeContainer {\n  width: 150px;\n  height: 150px;\n  margin: 0 auto;\n}\n\n.qrCode {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appBadge": "styles-module__appBadge--OQVys",
	"qrCodeContainer": "styles-module__qrCodeContainer--zaNVw",
	"qrCode": "styles-module__qrCode--LUojh"
};
export default ___CSS_LOADER_EXPORT___;
