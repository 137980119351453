import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { space, color, size, layout } from "styled-system";
import { darken } from "polished";

const StyledIcon = styled.i``;

const Button = styled.button`
  padding: 0;
  background: none;
  border: 0;
  display: flex;
  align-items: center;

  ${StyledIcon} {
    ${size}
    ${color}

    @media (hover: hover) {
      :hover {
        ${({ theme, hoverColor }) =>
          `color: ${theme.colors[hoverColor]};` ||
          `
        opacity: 0.7;
        `}
      }
    }
  }

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
    ${StyledIcon} {
      color: ${props => darken(0.1, props.theme.colors.lightGrey)};

      @media (hover: hover) {
        :hover {
          opacity: 0;
        }
      }
    }
  }

  ${space}
  ${layout}
`;

const IconButton = ({ Icon, onClick, ...rest }) => (
  <Button type="button" onClick={onClick} {...rest}>
    <StyledIcon as={Icon} />
  </Button>
);

IconButton.defaultProps = {
  width: "12px",
};

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default IconButton;
