import React from "react";
import Modal, { CloseModalButton } from "app/components/Modal";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { DownloadAppSection } from "../DownloadAppSection";
import styles from "./styles.module.scss";

interface Props {
  closeModal: () => void;
  title?: string;
}

export function DownloadAppModal({ closeModal, title }: Props) {
  return (
    <Modal
      onClose={closeModal}
      className={styles.modal}
      isOpen
      onBackgroundClick={closeModal}
    >
      <Flex justifyContent="flex-end" mb={2}>
        <CloseModalButton onClick={closeModal} variant="light" />
      </Flex>
      <Div>
        <DownloadAppSection title={title} />
      </Div>
    </Modal>
  );
}
