import React from "react";
import classNames from "classnames";
import { ColorName } from "theme/colors";
import styles from "./styles.module.scss";

export interface ProgressBarProps {
  className?: string;
  color?: ColorName;
  percent?: number;
  style?: React.CSSProperties;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  color = "primary",
  percent = 0,
  style,
  ...rest
}) => (
  <div
    className={classNames(styles.wrapper, className)}
    style={style}
    {...rest}
  >
    <div
      className={classNames(styles.progress, styles[color])}
      style={{ width: `${percent || 0}%`, opacity: percent ? 1 : 0 }}
    />
  </div>
);
