export function getDaysLeft(endTime: string): number {
  const endDate = new Date(endTime);
  const currentDate = new Date();
  const timeDifference = endDate.getTime() - currentDate.getTime();
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
}

export function getProgressPercentage(
  isCompleted: boolean,
  currentBadgeProgress: number,
  nextBadgeAmountToEarn: number
): number {
  if (isCompleted) {
    return 1;
  }
  if (nextBadgeAmountToEarn === 0) {
    return 0;
  }
  return currentBadgeProgress / nextBadgeAmountToEarn;
}

export function getBadgeUnit(triggerType?: string | null): string {
  switch (triggerType) {
    case "CLASS_COMPLETION":
    case "CLASS_TAKEN":
      return "classes";
    case "CLASS_MINUTES_SPENT":
      return "minutes spent";
    case "POST_COMMENT":
      return "comments";
    case "POST_UPLOAD":
      return "posts";
    default:
      return "";
  }
}
