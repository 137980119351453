import Icon, {
  CircleChevronLeft,
  CircleChevronRight,
} from "app/components/Icon";
import React, { useCallback, useState } from "react";
import SlickSlider from "react-slick";
import { IS_MOBILE } from "constants/index";
import { useCentered } from "./hooks/useCentered";
import ReactResizeDetector from "./react-resize-detector";
import { Slide } from "./Slide";
import "./style.css";

type SliderProps = {
  data: object[];
  slideComponent: any;
  slidesWidth?: number;
  slidesSpacing?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  infinite?: boolean;
  swipeToSlide?: boolean;
  arrowTopPosition?: number | string;
  showArrows?: boolean;
  prevArrow?: any;
  nextArrow?: any;
  centered?: boolean;
  centerPadding?: number;
  [x: string]: any;
};

const CustomPrevArrow = ({ currentSlide, slideCount, onClick, top, ...props }: any) => (
  <Icon as={CircleChevronLeft} top={top} onClick={onClick} {...props} />
);

const CustomNextArrow = ({ currentSlide, slideCount, onClick, top, ...props }: any) => (
  <Icon as={CircleChevronRight} top={top} onClick={onClick} {...props} />
);

export const Slider = ({
  data,
  slideComponent: SlideComponent,
  slidesWidth = 360,
  slidesSpacing = 0,
  slidesToShow = 3.5,
  slidesToScroll = 1,
  infinite,
  swipeToSlide = true,
  arrowTopPosition = "20%",
  showArrows = true,
  prevArrow = <CustomPrevArrow top={arrowTopPosition} />,
  nextArrow = <CustomNextArrow top={arrowTopPosition} />,
  centered = true,
  centerPadding = 20,
  ...props
}: SliderProps) => {
  const [itemsToShow, setItemsToShow] = useState(slidesToShow);
  const [shouldShowArrows, setShouldShowArrows] = useState(showArrows);

  const { shouldShowCenteredMode, centeredModePadding } = useCentered(
    centered,
    centerPadding
  );

  const onResizeSlider = useCallback(
    (wrapperWidth: number) => {
      if (!wrapperWidth || !data?.length) {
        return;
      }

      if (IS_MOBILE) {
        setShouldShowArrows(false);
      } else {
        setShouldShowArrows(true);
      }

      const slidesFullWidth = slidesWidth + slidesSpacing + centeredModePadding;
      setItemsToShow(wrapperWidth / slidesFullWidth);
    },
    [centeredModePadding, data, slidesSpacing, slidesWidth]
  );

  return (
    <ReactResizeDetector
      handleWidth
      refreshMode="throttle"
      refreshRate={1000}
      onResize={onResizeSlider}
    >
      <SlickSlider
        slidesToShow={itemsToShow}
        slidesToScroll={slidesToScroll}
        infinite={infinite}
        swipeToSlide={swipeToSlide}
        arrows={shouldShowArrows}
        prevArrow={prevArrow}
        nextArrow={nextArrow}
        centerMode={shouldShowCenteredMode}
        centerPadding={`${centeredModePadding}px`}
      >
        {data?.map((item: any, index: number) => (
          <Slide
            key={index}
            {...props}
            data={item}
            slideComponent={SlideComponent}
            width={slidesWidth}
          />
        ))}
      </SlickSlider>
    </ReactResizeDetector>
  );
};
